<template>
  <router-view></router-view>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  computed : {
    isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
  },
  watch: {
    '$route' () {
      this.closeSidebarRight();
    }
  },  
  data() {
    return {
      cdnBaseUrl: null,
      environmentVariables: {
        company_id_external: null,
        environment_id_external: null
      },
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/login')
      })
    },
    getEnvironmentVariables() {
      // Get the hostname
      this.platformHostname = window.location.hostname;
      // Set the params
      let params = {};
      params.hostname = this.platformHostname;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/platform/environments/dashboard', params)
      .then(res => {
        this.environmentVariables = res.data.data;
        // Set the dashboard title
        document.title = this.environmentVariables.dashboard_title;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.environmentVariables.primary_color);
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', this.environmentVariables.primary_color);
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', this.environmentVariables.secondary_color);
        // Set the dashboard font
        document.documentElement.style.setProperty('--DashboardFontFamily', this.environmentVariables.font_family);
        // Set the dashboard background image URL
        document.documentElement.style.setProperty('--DashboardBackgroundImage', 'url(' + process.env.VUE_APP_CDN_URL + '/base/app/experience-background.png)');     
        // Set the copyright text
        this.copyrightText = this.environmentVariables.copyright;
        // Set the favicon href
        document.querySelector("link[rel~='icon']").href = `${this.cdnBaseUrl}/base/dashboard/logo/${this.environmentVariables.environment_tag}_logo.png`;
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getCompanyVariables() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/variables/dashboard/company')
      .then(res => {
        this.companyVariables = res.data.data;
        // Set the dashboard title
        document.title = this.companyVariables.dashboard_title;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.companyVariables.environment_primary_color);        
        // Set default color variables
        let primaryColor = null;
        let secondaryColor = null;  
        // Set the primaryColor value
        (this.companyVariables.primary_color) ? primaryColor = this.companyVariables.primary_color : primaryColor = this.companyVariables.environment_primary_color;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', primaryColor);
        // Set the primaryColor value
        (this.companyVariables.secondary_color) ? secondaryColor = this.companyVariables.secondary_color : secondaryColor = this.companyVariables.environment_secondary_color;
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', secondaryColor);
        // Set the dashboard font
        if(this.companyVariables.font_family) document.documentElement.style.setProperty('--DashboardFontFamily', this.companyVariables.font_family);
        // Update the companyImageId data in localStorage
        if(this.companyVariables.company_image_id) localStorage.setItem('companyImageId', this.companyVariables.company_image_id);
        // Update the headerImageId data in localStorage
        if(this.companyVariables.header_image_id )localStorage.setItem('headerImageId', this.companyVariables.header_image_id);
        // Update the environmentTag data in localStorage
        if(this.companyVariables.environment_tag) localStorage.setItem('environmentTag', this.companyVariables.environment_tag);
        // Update the environmentHelpers data in localStorage
        localStorage.setItem('environmentHelpers', JSON.stringify({
          environment_name: this.companyVariables.environment_name,
          environment_website: this.companyVariables.environment_website,
          environment_contact_email: this.companyVariables.environment_contact_email,
          environment_contact_phone: this.companyVariables.environment_contact_phone,
          environment_help_url: this.companyVariables.environment_help_url,
          environment_terms_url: this.companyVariables.environment_terms_url,
          environment_privacy_policy_url: this.companyVariables.environment_privacy_policy_url,
          environment_schedule_call_url: this.companyVariables.environment_schedule_call_url
        }));
        // Set the dashboard background image URL
        document.documentElement.style.setProperty('--DashboardBackgroundImage', 'url(' + process.env.VUE_APP_CDN_URL + '/base/app/experience-background.png)');
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPlatform() {
      let params = {};      
      (localStorage.getItem('token') !== null) ? params.local_token = localStorage.getItem('token') : params.local_token = null;
      (localStorage.getItem('companyIdExternal') !== null) ? params.company_id_external = localStorage.getItem('companyIdExternal') : params.company_id_external = null;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/platform/dashboard/check', params)
      .then(res => {
        let companyTokenValid = res.data.data.company_token_valid;
        let companyLicenseActive = res.data.data.company_license_active;
        let platformRoleTags = res.data.data.platform_role_tags;
        // Logout if the company token is invalid, the company license is deactivated of the user has no permissions
        if(companyTokenValid === false || companyLicenseActive === false || platformRoleTags === null) this.logout();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkUserData() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/me')
      .then(res => {
        // Update the companyIdExternal data in localStorage
        localStorage.setItem('companyIdExternal', res.data.data.company_id_external);
      })
      .catch(err => {
        console.error(err); 
      }); 
    }  
  },
  mounted: function() {
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
  },
  created: function () {
    // Check if the user is logged in
    if(this.isLoggedIn) {
      // Check the user data
      this.checkUserData();
      // Check if the company licence is still active
      this.checkPlatform();
      // If the licence is active, get the company platform variables
      this.getCompanyVariables();
    } else {
      // If not, get the default platform variables
      this.getEnvironmentVariables();
    }

    (localStorage.getItem('userPlatformLanguage') !== null) ? this.$i18n.locale = localStorage.getItem('userPlatformLanguage') : this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE;    

    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(logout)
        }
        throw err;
      });
    });
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>