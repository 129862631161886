import Vue from 'vue'
import Router from 'vue-router'
import store from './../store.js'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Login = () => import('@/views/Login')
const Register = () => import('@/views/Register')
const PasswordReset = () => import('@/views/PasswordReset')
const ActivateLoyalty = () => import('@/views/ActivateLoyalty')
const PaymentStatus = () => import('@/views/PaymentStatus')

// Admin
const Admin_Options = () => import('@/views/admin/Options')
const Admin_Wkr_Calculator = () => import('@/views/admin/wkr/Calculator')
const Admin_Default_Event_Email_Content = () => import('@/views/admin/events/DefaultEventEmailContent')

// Connect
const Connect_Social_Connect = () => import('@/views/connect/SocialConnect')
const Connect_Flows = () => import('@/views/connect/Flows')
const Connect_Deliver = () => import('@/views/connect/Deliver')
const Connect_Deliver_Mail_Campaign = () => import('@/views/connect/deliver/mail/Campaign')
const Connect_Deliver_Mail_Template = () => import('@/views/connect/deliver/mail/Template')
const Connect_Senders = () => import('@/views/connect/Senders')
const Connect_Auto_Moment = () => import('@/views/connect/AutoMoment')
const Connect_Auto_Moments = () => import('@/views/connect/AutoMoments')
const Connect_Topics = () => import('@/views/connect/Topics')
const Connect_Signage = () => import('@/views/connect/Signage')
const Connect_Signage_Screen = () => import('@/views/connect/signage/screens/Screen')
const Connect_Chats = () => import('@/views/connect/Chats')

// Measure
const Measure_Burning_Matters = () => import('@/views/measure/BurningMatters')
const Measure_SatisQuestions = () => import('@/views/measure/SatisQuestions')
const Measure_Configure = () => import('@/views/measure/Configure')
const Measure_Auto_Moment = () => import('@/views/measure/AutoMoment')
const Measure_Auto_Moments = () => import('@/views/measure/AutoMoments')

// Knowledge
const Knowledge_Library = () => import('@/views/knowledge/Library')
const Knowledge_Feedback = () => import('@/views/knowledge/Feedback')
const Knowledge_Submissions = () => import('@/views/knowledge/Submissions')
const Knowledge_Auto_Moment = () => import('@/views/knowledge/AutoMoment')
const Knowledge_Auto_Moments = () => import('@/views/knowledge/AutoMoments')

// Learn
const Learn_Catalog = () => import('@/views/learn/Catalog')
const Learn_Auto_Moment = () => import('@/views/learn/AutoMoment')
const Learn_Auto_Moments = () => import('@/views/learn/AutoMoments')
const Learn_Onboarding = () => import('@/views/learn/Onboarding')

// Loyalty
const Loyalty_Challenges = () => import('@/views/loyalty/Challenges')
const Loyalty_Auto_Moment = () => import('@/views/loyalty/AutoMoment')
const Loyalty_Auto_Moments = () => import('@/views/loyalty/AutoMoments')
const Loyalty_Orders = () => import('@/views/loyalty/Orders')
const Loyalty_Rewards = () => import('@/views/loyalty/Rewards')
const Loyalty_Moments = () => import('@/views/loyalty/Moments')
const Loyalty_Moment = () => import('@/views/loyalty/Moment')
const Loyalty_Moments_Templates = () => import('@/views/loyalty/MomentsTemplates')
const Loyalty_Moments_Template = () => import('@/views/loyalty/MomentsTemplate')
const Loyalty_Edit_Moment = () => import('@/views/loyalty/EditMoment')
const Loyalty_Send_Moment = () => import('@/views/loyalty/SendMoment')
const Loyalty_Store = () => import('@/views/loyalty/Store')
const Loyalty_Benefits_Moments = () => import('@/views/loyalty/BenefitsMoments')
const Loyalty_Benefits_Moment = () => import('@/views/loyalty/BenefitsMoment')
const Loyalty_Benefits_Moment_Template = () => import('@/views/loyalty/BenefitsMomentTemplate')
const Loyalty_Budget_Caps = () => import('@/views/loyalty/BudgetCaps')

// Hub
const Hub_Resources = () => import('@/views/hub/Resources')
const Hub_Tasks = () => import('@/views/hub/Tasks')
const Hub_Todos = () => import('@/views/hub/Todos')
const Hub_Todos_Templates = () => import('@/views/hub/todos/Templates')
const Hub_Todos_List = () => import('@/views/hub/todos/List')
const Hub_Todos_Categories = () => import('@/views/hub/todos/Categories')

// Insights
const Insights = () => import('@/views/insights/Insights')
const Insights_Connect_Post = () => import('@/views/insights/connect/Post')
const Insights_Connect_Topics = () => import('@/views/insights/connect/Topics')
const Insights_Connect_Subtopics = () => import('@/views/insights/connect/Subtopics')
const Insights_Measure_Categories = () => import('@/views/insights/measure/Categories')
const Insights_Measure_Category = () => import('@/views/insights/measure/Category')
const Insights_Measure_Subcategory = () => import('@/views/insights/measure/Subcategory')
const Insights_Measure_Burning_Matter = () => import('@/views/insights/measure/BurningMatter')
const Insights_Knowledge = () => import('@/views/insights/knowledge/Overall')
const Insights_Knowledge_Item = () => import('@/views/insights/knowledge/Item')
const Insights_Learn = () => import('@/views/insights/learn/Overall')
const Insights_Learn_Courses = () => import('@/views/insights/learn/Courses')
const Insights_Learn_Onboarding = () => import('@/views/insights/learn/Onboarding')
const Insights_Loyalty_Store = () => import('@/views/insights/loyalty/Store')
const Insights_Loyalty_Teams = () => import('@/views/insights/loyalty/Teams')
const Insights_Loyalty_Employees = () => import('@/views/insights/loyalty/Employees')
const Insights_Loyalty_EmployeePoints = () => import('@/views/insights/loyalty/EmployeePoints')
const Insights_Loyalty_EmployeePointsUpdates = () => import('@/views/insights/loyalty/EmployeePointsUpdates')
const Insights_Crossover_Audience = () => import('@/views/insights/crossover/Audience')
const Insights_Crossover_Participation = () => import('@/views/insights/crossover/Participation')

// Core
const Core_License = () => import('@/views/core/License')
const Core_System_Communication = () => import('@/views/core/SystemCommunication')
const Core_System_Communications = () => import('@/views/core/SystemCommunications')
const Core_Look_And_Feel = () => import('@/views/core/LookAndFeel')
const Core_Organization = () => import('@/views/core/Organisation')
const Core_Target_Groups = () => import('@/views/core/TargetGroups')
const Core_Routes = () => import('@/views/core/Routes')
const Core_TimedMoments = () => import('@/views/core/TimedMoments')
const Core_Timeline = () => import('@/views/core/Timeline')
const Core_User_Management_Import_Users = () => import('@/views/core/user-management/ImportUsers')
const Core_Email_Templates = () => import('@/views/core/email/Templates')
const Core_Email_Template = () => import('@/views/core/email/Template')
const Core_Payments_Setup = () => import('@/views/core/payments/Setup')
const Core_Payments_Recurring_Setup = () => import('@/views/core/payments/recurring/Setup')
const Core_Payments_Prepaid_Top_Up = () => import('@/views/core/payments/prepaid/TopUp')

// Service
const Service_Questions = () => import('@/views/service/questions/Questions')
const Service_Question_Folders = () => import('@/views/service/questions/Folders')
const Service_Forms = () => import('@/views/service/forms/Forms')
const Service_Form = () => import('@/views/service/forms/Form')
const Service_Form_Submissions = () => import('@/views/service/forms/Submissions')
const Service_Single_Form_Submissions = () => import('@/views/service/forms/FormSubmissions')

// Authentication
const Auth_Setup = () => import('@/views/auth/Setup')
const Auth_Verify = () => import('@/views/auth/Verify')

// Status Pages
const Page_404 = () => import('@/views/pages/404')
const Page_500 = () => import('@/views/pages/500')

Vue.use(Router)

let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next()
  }
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'admin',
          meta: { label: 'Admin'},
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Admin_Options           
            },
            {
              path: 'wkr/:id/calculator',
              meta: { label: 'WKR Budget Calculator' },
              name: 'WKR Budget Calculator',
              component: Admin_Wkr_Calculator
            },
            {
              path: 'events/default/email/:id',
              meta: { label: 'Default Email Content' },
              name: 'Default Email Content',
              component: Admin_Default_Event_Email_Content
            }
          ]
        },
        {
          path: 'dashboard',
          meta: { label: 'Dashboard' },
          name: 'Dashboard',          
          component: Dashboard
        },
        {
          path: 'connect',
          meta: { label: 'Connect' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'schedule',
              meta: { label: 'Schedule' },
              name: 'Schedule',
              component: Connect_Social_Connect,
            },
            {
              path: 'deliver',
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  meta: { label: 'Deliver' },
                  name: 'Deliver',                  
                  component: Connect_Deliver,
                },
                {
                  path: 'campaign/new',
                  meta: { label: 'Campaign' },
                  name: 'Campaign',
                  component: Connect_Deliver_Mail_Campaign,
                },
                {
                  path: 'campaign/:id',
                  meta: { label: 'Campaign Details' },
                  name: 'Campaign Details',
                  component: Connect_Deliver_Mail_Campaign,
                },
                {
                  path: 'template/new',
                  meta: { label: 'Template' },
                  name: 'Template',
                  component: Connect_Deliver_Mail_Template,
                },
                {
                  path: 'template/:id',
                  meta: { label: 'Template Details' },
                  name: 'Template Details',
                  component: Connect_Deliver_Mail_Template,
                }                
              ]
            },
            {
              path: 'senders',
              meta: { label: 'Senders' },
              name: 'Senders',
              component: Connect_Senders,
            },                 
            {
              path: 'flows',
              meta: { label: 'Flows' },
              name: 'Flows',
              component: Connect_Flows,
            },
            {
              path: 'chats',
              meta: { label: 'Chats' },
              name: 'Chats',
              component: Connect_Chats,
            },            
            {
              path: 'auto-moments',
              meta: { 
                label: 'Auto-Moments',
                requiresAuth: true 
              },
              name: 'Auto-Moments (Connect)',
              component: Connect_Auto_Moments,
            },
            {
              path: 'auto-moment/:id',
              meta: { 
                label: 'Auto-Moment',
                requiresAuth: true 
              },
              name: 'Auto-Moment (Connect)',
              component: Connect_Auto_Moment,
            },
            {
              path: 'topics',
              meta: { label: 'Topics' },
              name: 'Topics',
              component: Connect_Topics,
            },            
            {
              path: 'signage',
              meta: { label: 'Signage'},
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  component: Connect_Signage
                },         
                {
                  path: 'screens/:id',
                  meta: { label: 'Screen details' },
                  name: 'Screen details',
                  component: Connect_Signage_Screen,
                }
              ]
            },                      
          ]
        },
        {
          path: 'measure',
          meta: { label: 'Measure' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'satisquestions',
              name: 'SatisQuestions',
              meta: { 
                label: 'SatisQuestions',
                requiresAuth: true
              },              
              component: Measure_SatisQuestions,
            },          
            {
              path: 'burning-matters',
              name: 'Burning Matters',
              meta: { 
                label: 'Burning Matters',
                requiresAuth: true
              },
              component: Measure_Burning_Matters,
            },
            {
              path: 'configure',
              name: 'Configure',
              meta: { 
                label: 'Configure',
                requiresAuth: true
              },
              component: Measure_Configure,
            },
            {
              path: 'auto-moments',
              name: 'Auto-Moments (Measure)',
              meta: { 
                label: 'Auto-Moments',
                requiresAuth: true 
              },
              component: Measure_Auto_Moments,
            },
            {
              path: 'auto-moment/:id',
              name: 'Auto-Moment (Measure)',
              meta: { 
                label: 'Auto-Moment',
                requiresAuth: true
              },
              component: Measure_Auto_Moment,
            }            
          ]
        },
        {
          path: 'knowledge',
          meta: { label: 'Knowledge' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'library',
              name: 'Library',
              meta: { 
                label: 'Library',
                requiresAuth: true
              },              
              component: Knowledge_Library,
            },
            {
              path: 'feedback',
              name: 'Feedback',
              meta: { 
                label: 'Feedback',
                requiresAuth: true
              },
              component: Knowledge_Feedback,
            },
            {
              path: 'submissions',
              name: 'Submissions',
              meta: { 
                label: 'Submissions',
                requiresAuth: true
              },
              component: Knowledge_Submissions,
            }, 
            {
              path: 'auto-moments',
              name: 'Auto-Moments (Knowledge)',
              meta: { 
                label: 'Auto-Moments',
                requiresAuth: true
              },
              component: Knowledge_Auto_Moments,
            },
            {
              path: 'auto-moment/:id',
              name: 'Auto-Moment (Knowledge)',
              meta: { 
                label: 'Auto-Moment',
                requiresAuth: true
              },
              component: Knowledge_Auto_Moment,
            }             
          ]
        },
        {
          path: 'learn',
          meta: { label: 'Learn' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'catalog',
              name: 'Catalog',
              meta: { 
                label: 'Catalog',
                requiresAuth: true
              },              
              component: Learn_Catalog,
            },
            {
              path: 'auto-moments',
              name: 'Auto-Moments (Learn)',
              meta: { 
                label: 'Auto-Moments',
                requiresAuth: true
              },
              component: Learn_Auto_Moments,
            },
            {
              path: 'auto-moment/:id',
              name: 'Auto-Moment (Learn)',
              meta: { 
                label: 'Auto-Moment',
                requiresAuth: true
              },
              component: Learn_Auto_Moment,
            },             
            {
              path: 'onboarding',
              name: 'Onboarding',
              meta: { 
                label: 'Onboarding',
                requiresAuth: true
              },              
              component: Learn_Onboarding,
            }
          ]
        },        
        {
          path: 'loyalty',
          meta: { label: 'Loyalty' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'team-challenges',
              name: 'Team Challenges',
              meta: { 
                label: 'Team Challenges',
                requiresAuth: true
              },
              component: Loyalty_Challenges,
            },            
            {
              path: 'store',
              name: 'Store',
              meta: { 
                label: 'Store',
                requiresAuth: true
              },
              component: Loyalty_Store,
            },
            {
              path: 'orders',
              name: 'Orders',
              meta: { 
                label: 'Orders',
                requiresAuth: true
              },
              component: Loyalty_Orders,
            },                        
            {
              path: 'auto-moments',
              name: 'Auto-Moments (Loyalty)',
              meta: { 
                label: 'Auto-Moments',
                requiresAuth: true
              },
              component: Loyalty_Auto_Moments,
            },
            {
              path: 'auto-moment/:id',
              meta: { 
                label: 'Auto-Moment',
                requiresAuth: true
              },
              name: 'Auto-Moment (Loyalty)',
              component: Loyalty_Auto_Moment,
            },
            {
              path: 'moments',
              name: 'Moments',
              meta: { 
                label: 'Moments',
                requiresAuth: true
              },
              component: Loyalty_Moments,
            }, 
            {
              path: 'moment/:id',
              meta: { label: 'Moment' },
              name: 'Moment',
              component: Loyalty_Moment,
            },
            {
              path: 'moment/:id/edit',
              meta: { label: 'Edit Moment' },
              name: 'Edit Moment',
              component: Loyalty_Edit_Moment,
            },
            {
              path: 'moments/templates',
              name: 'Moments Templates',
              meta: { 
                label: 'Moments Templates',
                requiresAuth: true
              },
              component: Loyalty_Moments_Templates,
            },
            {
              path: 'moments/template/:id',
              meta: { label: 'Moments Template' },
              name: 'Moments Template',
              component: Loyalty_Moments_Template,
            },
            {
              path: 'moments/send',
              meta: { label: 'Send Moment' },
              name: 'Send Moment',
              component: Loyalty_Send_Moment,
            },
            {
              path: 'benefits/moments',
              name: 'Benefits Moments',
              meta: { 
                label: 'Benefits Moments',
                requiresAuth: true
              },
              component: Loyalty_Benefits_Moments,
            },        
            {
              path: 'benefits/moment/template',
              meta: { label: 'Benefits Moment Template' },
              name: 'Benefits Moment Template',
              component: Loyalty_Benefits_Moment_Template,
            },            
            {
              path: 'benefits/moment/:id',
              meta: { label: 'Benefits Moment' },
              name: 'Benefits Moment',
              component: Loyalty_Benefits_Moment,
            },
            {
              path: 'budget-caps',
              meta: { label: 'Budget Caps' },
              name: 'Budget Caps',
              component: Loyalty_Budget_Caps,
            },                                        
            // {
            //   path: 'rewards',
            //   name: 'Rewards',
            //   component: Loyalty_Rewards,
            //   meta: {
            //     label: 'Rewards',
            //     requiresAuth: true
            //   }
            // }                                                        
          ]
        },         
        {
          path: 'hub',
          meta: { label: 'Hub' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'resources',
              name: 'Resources',
              meta: { 
                label: 'Resources',
                requiresAuth: true
              },              
              component: Hub_Resources,
            },
            {
              path: 'tasks',
              name: 'Tasks',
              component: Hub_Tasks,
              meta: {
                label: 'Tasks',
                requiresAuth: true
              }
            },
            {
              path: 'todo-lists',
              meta: { label: 'Todo Lists'},
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  component: Hub_Todos           
                },
                {
                  path: 'templates',
                  meta: { label: 'Templates' },
                  name: 'Templates',
                  component: Hub_Todos_Templates,
                },
                {
                  path: 'categories',
                  meta: { label: 'Categories' },
                  name: 'Categories',
                  component: Hub_Todos_Categories,
                },                        
                {
                  path: ':id',
                  meta: { label: 'Todo List Details' },
                  name: 'Todo List Details',
                  component: Hub_Todos_List,
                }         
              ]
            },            
          ]
        },
        {
          path: 'service',
          meta: { label: 'Service' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'questions',
              name: 'Questions',
              meta: { 
                label: 'Questions',
                requiresAuth: true
              },              
              component: Service_Questions,
            },
            {
              path: 'questions/folders',
              name: 'Question Folders',
              meta: { 
                label: 'Question Folders',
                requiresAuth: true
              },              
              component: Service_Question_Folders,
            },
            {
              path: 'forms',
              name: 'Forms',
              meta: { 
                label: 'Forms',
                requiresAuth: true
              },
              component: Service_Forms,
            },
            {
              path: 'forms/submissions',
              meta: { 
                label: 'Form Submissions',
                requiresAuth: true
              },
              name: 'Form Submissions',
              component: Service_Form_Submissions
            },
            {
              path: 'form/:id/submissions',
              meta: { 
                label: 'Form Submissions',
                requiresAuth: true
              },
              name: 'Single Form Submissions',
              component: Service_Single_Form_Submissions
            },                        
            {
              path: 'form/new',
              name: 'Form',
              meta: { 
                label: 'Form',
                requiresAuth: true
              },
              component: Service_Form,
            },
            {
              path: 'form/:id',
              meta: { 
                label: 'Form Details',
                requiresAuth: true
              },
              name: 'Form Details',
              component: Service_Form,
            }
          ]
        },                          
        {
          path: 'insights',
          meta: { label: 'Insights'},
          component: {
            render(c) { return c('router-view') }
          },
          children: [  
            {
              path: '',
              component: Insights
            },
            {
              path: 'participation',
              meta: { label: 'Participation'},
              name: 'Participation',
              component: Insights_Crossover_Participation,
            },
            {
              path: 'audience',
              meta: { label: 'Audience' },
              name: 'Audience',
              component: Insights_Crossover_Audience,
            },                        
            {
              path: 'connect',
              meta: { label: 'Connect'},
              component: {
                render(c) { return c('router-view') }
              },
              children: [         
                {
                  path: 'post/:id',
                  meta: { label: 'Post Insights' },
                  name: 'Post Insights',
                  component: Insights_Connect_Post,
                },        
                {
                  path: 'topics',
                  meta: { label: 'Topics Insights' },
                  name: 'Topics Insights',
                  component: Insights_Connect_Topics,
                },
                {
                  path: 'topic/:id',
                  meta: { label: 'Topic Insights' },
                  name: 'Topic Insights',
                  component: Insights_Connect_Subtopics,
                },
              ]
            },
            {
              path: 'measure',
              meta: { label: 'Measure'},
              component: {
                render(c) { return c('router-view') }
              },
              children: [         
                {
                  path: 'categories',
                  meta: { label: 'Categories Insights' },
                  name: 'Categories Insights',
                  component: Insights_Measure_Categories,
                },
                {
                  path: 'category/:id',
                  meta: { label: 'Category Insights' },
                  name: 'Category Insights',
                  component: Insights_Measure_Category,
                },
                {
                  path: 'subcategory/:id',
                  meta: { label: 'Subcategory Insights' },
                  name: 'Subcategory Insights',
                  component: Insights_Measure_Subcategory,
                },
                {
                  path: 'burning-matter/:id',
                  meta: { label: 'Burning Matter Insights' },
                  name: 'Burning Matter Insights',
                  component: Insights_Measure_Burning_Matter,
                }
              ]
            },
            {
              path: 'knowledge',
              meta: { label: 'Knowledge'},
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  meta: { label: 'Knowledge Insights' },
                  name: 'Knowledge Insights',
                  component: Insights_Knowledge,
                },
                {
                  path: 'item/:id',
                  meta: { label: 'Knowledge Item Insights' },
                  name: 'Knowledge Item Insights',
                  component: Insights_Knowledge_Item,
                }
              ]
            },                                 
            {
              path: 'learn',
              meta: { label: 'Learn'},
              component: {
                render(c) { return c('router-view') }
              },
              children: [         
                {
                  path: '',
                  meta: { label: 'Learn Insights' },
                  name: 'Learn Insights',
                  component: Insights_Learn,
                },                
                {
                  path: 'onboarding',
                  meta: { label: 'Onboarding Insights' },
                  name: 'Onboarding Insights',
                  component: Insights_Learn_Onboarding,
                },                
                {
                  path: 'courses',
                  meta: { label: 'Courses Insights' },
                  name: 'Courses Insights',
                  component: Insights_Learn_Courses,
                }              
              ]
            },
            {
              path: 'loyalty',
              meta: { label: 'Loyalty'},
              component: {
                render(c) { return c('router-view') }
              },
              children: [         
                {
                  path: 'points/employees',
                  meta: { label: 'Employees Points' },
                  name: 'Employees Points',
                  component: Insights_Loyalty_EmployeePoints,
                },
                {
                  path: 'points/employees/updates',
                  meta: { label: 'Employees Points Updates' },
                  name: 'Employees Points Updates',
                  component: Insights_Loyalty_EmployeePointsUpdates,
                },                
                {
                  path: 'store',
                  meta: { label: 'Store' },
                  name: 'Store Insights',
                  component: Insights_Loyalty_Store,
                },
                {
                  path: 'teams',
                  meta: { label: 'Teams' },
                  name: 'Team Insights',
                  component: Insights_Loyalty_Teams,
                },
                {
                  path: 'employees',
                  meta: { label: 'Employees' },
                  name: 'Employee Insights',
                  component: Insights_Loyalty_Employees,
                }                                                
              ]
            }
          ]
        },
        {
          path: 'core',
          meta: { label: 'Core' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'license',
              meta: { label: 'License' },
              name: 'License',
              component: Core_License,
            },
            {
              path: 'organization',
              meta: { label: 'Organization' },
              name: 'Organization',
              component: Core_Organization,
            },
            {
              path: 'user-management',
              meta: { label: 'User Management'},
              component: {
                render(c) { return c('router-view') }
              },
              children: [          
                {
                  path: 'import',
                  meta: { label: 'User Import' },
                  name: 'User Import',
                  component: Core_User_Management_Import_Users,
                }         
              ]
            },            
            {
              path: 'target-groups',
              meta: { label: 'Target Groups'},
              name: 'TargetGroups',
              component: Core_Target_Groups,
            },
            {
              path: 'routes',
              meta: { label: 'Routes'},
              name: 'Routes',
              component: Core_Routes,
            },            
            {
              path: 'look-and-feel',
              meta: { label: 'Look and feel' },
              name: 'LookAndFeel',
              component: Core_Look_And_Feel,
            },
            {
              path: 'system-communications',
              meta: { label: 'System Communications' },
              name: 'System Communications',
              component: Core_System_Communications,
            },
            {
              path: 'system-communication/:id',
              name: 'System Communication',
              meta: { 
                label: 'System Communication',
                requiresAuth: true
              },
              component: Core_System_Communication,
            },            
            {
              path: 'email',
              meta: { label: 'Email'},
              component: {
                render(c) { return c('router-view') }
              },
              children: [          
                {
                  path: 'templates',
                  meta: { label: 'Email Templates' },
                  name: 'Email Templates',
                  component: Core_Email_Templates,
                },
                {
                  path: 'template/new',
                  meta: { label: 'Email Template' },
                  name: 'New Email Template',
                  component: Core_Email_Template,
                },
                {
                  path: 'template/:id',
                  meta: { label: 'Email Template' },
                  name: 'Email Template',
                  component: Core_Email_Template,
                }                       
              ]
            },
            {
              path: 'payments',
              meta: { label: 'Payments'},
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: 'setup',
                  meta: { label: 'Payments Setup' },
                  name: 'Payments Setup',
                  component: Core_Payments_Setup,
                },                
                {
                  path: 'recurring/setup',
                  meta: { label: 'Recurring Payments Setup' },
                  name: 'Recurring Payments Setup',
                  component: Core_Payments_Recurring_Setup,
                },                                                               
                {
                  path: 'prepaid/topup',
                  meta: { label: 'Prepaid Top Up' },
                  name: 'Prepaid Top Up',
                  component: Core_Payments_Prepaid_Top_Up,
                }
              ]
            },                        
            // {
            //   path: 'timed-moments',
            //   name: 'Timed Moments',
            //   component: Core_TimedMoments,
            //   meta: {
            //     label: 'Timed Moments',
            //     requiresAuth: true
            //   }
            // },
            // {
            //   path: 'timeline',
            //   name: 'Timeline',
            //   component: Core_Timeline,
            //   meta: {
            //     label: 'Timeline',
            //     requiresAuth: true
            //   }
            // }            
          ]
        }       
      ]
    },
    {
      path: '/auth',
      meta: { label: 'Auth' },
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: 'setup',
          meta: { label: 'Setup', requiresAuth: true },
          name: 'Setup',
          component: Auth_Setup,
        },
        {
          path: 'verify',
          meta: { label: 'Verify' },
          name: 'Verify',
          component: Auth_Verify,
        }
      ]
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/password-reset',
      meta: { label: 'Password Reset'},
      component: {
        render(c) { return c('router-view') }
      },
      children: [                       
        {
          path: ':id',
          meta: { label: 'Password Reset' },
          name: 'Password Reset',
          component: PasswordReset,
        }         
      ]
    },
    {
      path: '/activate/loyalty',
      meta: { label: 'Activate your account'},
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: ':id',
          meta: { label: 'Activate your account' },
          name: 'Activate your account',
          component: ActivateLoyalty,
        }         
      ]
    },    
    {
      path: '/payment-status/:id',
      name: 'Payment Status',
      component: PaymentStatus
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Not found',
          component: Page_404
        },
        {
          path: '500',
          name: 'Internal Server Error',
          component: Page_500
        }
      ]
    }
  ]
}

export default router
